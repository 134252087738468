import { useProceduresDetectionsPolygon, useProceduresFreezeFrames } from '../../aceapi/aceComponents';
import { useParams } from 'react-router-dom';
import { useAceApp } from '../Menu/ReportAppSelector';
import { binarySearch, filterSmallIntervals, findIntervals } from '../Charts/timeline/utils';
import { useMemo } from 'react';

export default function useCaduFreezeFrame(persistence = 1000) {
    const { uuid } = useParams();
    const { app } = useAceApp();

    const { data: freezeFrames } = useProceduresFreezeFrames(
        {
            pathParams: { procedureId: uuid },
            queryParams: { app },
        },
        { enabled: app === 'CADU' },
    );

    const { data: polygons } = useProceduresDetectionsPolygon(
        {
            pathParams: { procedureId: uuid },
            queryParams: { app },
        },
        { enabled: app === 'CADU' },
    );

    return useMemo(() => {
        if (!Array.isArray(freezeFrames) || !Array.isArray(polygons)) {
            return [];
        }

        const allIntervals = findIntervals(
            freezeFrames?.map((x) => x.timestamp),
            1000,
        );

        const intervals = filterSmallIntervals(allIntervals, 500);

        return intervals
            .map(([start]) => start)
            .filter((start) => {
                const idx = binarySearch(polygons, start, (el, x) => el - x.timestamp);
                return idx >= 0 && idx < polygons.length && Math.abs(polygons[idx].timestamp - start) < persistence;
            });
    }, [freezeFrames, polygons, persistence]);
}
