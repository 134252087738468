import { Divider, Stack, Typography } from '@mui/material';
import ReportBarChart from '../../Charts/stats/ReportBarChart';
import GroupedProcedureTables from '../widgets/GroupedProcedureTables';
import { useEffect, useState } from 'react';
import { timedelta } from '../utils';
import useCommonSummaryFilters from '../hooks/useCommonSummaryFilters';
import SummaryViewDump from '../SummaryViewDump';
import { useAceApp } from '../../Menu/ReportAppSelector';
import { useExtraList, useSummaryProceduresMaxDuration } from '../../../aceapi/aceComponents';

export default function ProcedureCountStat({
    type,
    groups,
    registerOption,
    renameOption,
    options,
    optionTypes,
    tableOpt,
    pin,
}) {
    const { app } = useAceApp();
    const { data: extraAnnotations } = useExtraList({ queryParams: { app } });

    const { data: procs } = useSummaryProceduresMaxDuration({ queryParams: { app } });
    const { filteredGroups, filteredProcs } = useCommonSummaryFilters({
        groups,
        procs,
        registerOption,
        renameOption,
        options,
        optionTypes,
    });

    useEffect(() => {
        registerOption(
            'date',
            'Date selector',
            optionTypes.DATE_SELECTOR,
            { name: 'lifetime' },
            {
                options: [
                    { key: 'lifetime', name: 'Lifetime', value: { name: 'lifetime' } },
                    { key: 'day', name: 'Last 24 hours', value: { name: 'day', from: timedelta.DAY } },
                    { key: 'week', name: 'Last 7 days', value: { name: 'week', from: timedelta.WEEK } },
                    { key: 'month', name: 'Last 28 days', value: { name: 'month', from: timedelta.MONTH } },
                    { key: 'year', name: 'Last 365 days', value: { name: 'year', from: timedelta.YEAR } },
                ],
            },
        );
        registerOption('details', 'Display procedures instead of count');
        registerOption(
            'extra',
            'Extra column to compute and show on the tables (requires above on)',
            optionTypes.STRING_SELECTOR,
            0,
            {
                options: [
                    { key: 'duration', name: 'Procedure Duration', value: 0 },
                    { key: 'bad_net', name: 'Cumulated network disruption time', value: 1 },
                    { key: 'det_off', name: 'Cumulated detection off time', value: 2 },
                    { key: 'bad_vm', name: 'Cumulated Bad Visible Mucosa (< 50%)', value: 3 },
                    { key: 'time', name: 'Time of the day', value: 4 },
                ],
            },
        );
    }, [optionTypes, registerOption]);

    const [data, setData] = useState([]);

    useEffect(() => {
        const dateOptionFilter = (procs) => {
            let q = procs;
            const date_range = options?.['date']?.value;
            const date_type = date_range?.name;
            const date_transform = date_type === 'custom' ? (i) => i : (i) => Date.now() - i;
            if (date_range?.from) q = q.filter((p) => p.start > date_transform(date_range.from));
            if (date_range?.to) q = q.filter((p) => p.start < date_transform(date_range.to));
            return q;
        };

        const get_annotation = (proc, annotation) =>
            extraAnnotations.find((ea) => ea.procedure_id === proc.procedure_id && ea.annotation === annotation)?.[
                'value'
            ] ?? 'Err';

        const dtformat = new Intl.DateTimeFormat('en-GB', { timeZone: 'Europe/London', timeStyle: 'long' });

        const annotatedGroups = filteredGroups
            .map((group) => {
                let customProcs = dateOptionFilter(filteredProcs.filter((x) => group.users.includes(x.username)));
                const extra = options?.['extra'];
                if ((extra?.value ?? 0) >= 1) {
                    const key = extra.extraProps.options[extra.value].key;
                    customProcs = customProcs.map((proc) => ({
                        ...proc,
                        [key]: extra.value === 4 ? dtformat.format(proc.start) : get_annotation(proc, key),
                    }));
                }
                return {
                    procs: customProcs,
                    count: customProcs.length,
                    ...group,
                };
            })
            .filter((x) => x.count > 0)
            .sort((x, y) => y.count - x.count);

        setData(annotatedGroups);
    }, [extraAnnotations, filteredGroups, filteredProcs, options]);

    return (
        <>
            <Stack direction='row' spacing={4} alignItems='center'>
                <Typography variant='h4'>Procedure count per {type}</Typography>
                <SummaryViewDump data={data} type={type} />
            </Stack>
            <Divider />
            {options['details']?.value ? (
                <GroupedProcedureTables
                    data={data}
                    tableOpt={tableOpt}
                    extraColumnId={options?.['extra']?.value ?? 0}
                    pin={pin}
                />
            ) : (
                <ReportBarChart
                    data={data}
                    nameKey='name'
                    valueKey='count'
                    yFormat={(name, i) => {
                        const short = data?.[i]?.short;
                        if (short) return short;
                        return name;
                    }}
                />
            )}
        </>
    );
}
