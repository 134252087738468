import Grid from '@mui/material/Grid';
import ACECaseListSummary from '../components/clinical/ACECaseListSummary';
import ClinicalReportStatusList from '../components/clinical/ClinicalReportStatusList';
import Exclusions from '../components/clinical/Exclusions';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import React, { createElement } from 'react';
import { useAceApp } from '../components/Menu/ReportAppSelector';
import { useGgCheckConnectionRead, useSourcesList } from '../aceapi/aceComponents';
import AnomalyReport from '../components/clinical/guru/anomaly/AnomalyReport';
import QuerySummary from '../components/clinical/guru/queries/QuerySummary';
import { Stack } from '@mui/material';
import GGTableSummary from '../components/clinical/guru/summary/GGTableSummary';
import GGAuthErrorBox from '../components/clinical/guru/GGAuthErrorBox';
import PolypAnnotationDownload from '../components/celery/PolypAnnotationDownload';
import CollapsiblePaperCard from '../components/report/CollapsiblePaperCard';
import OpticalDiagnosis from '../components/clinical/guru/explore/OpticalDiagnosis';
import DownloadMergedHistologyData from '../components/celery/DownloadMergedHistologyData';
import { useParams, useNavigate } from 'react-router-dom';

function SummaryTab() {
    const { app } = useAceApp();
    const { data: sources } = useSourcesList({});
    const dashboard_url = sources.find((source) => source.nickname === app)?.grafana_dashboard_url;

    return (
        <Stack direction='column' spacing={2}>
            <GGTableSummary />
            {dashboard_url && (
                <iframe
                    src={dashboard_url}
                    style={{ width: '100%', height: '100vh', border: 'none' }}
                    title='Summary Tab'
                />
            )}
        </Stack>
    );
}
function ExploreTab() {
    return <OpticalDiagnosis />;
}
function IssuesTab() {
    return <AnomalyReport />;
}

function QueriesTab() {
    return <QuerySummary />;
}

function CasesTab() {
    return (
        <Grid container spacing={2}>
            <CollapsiblePaperCard title='Polyp Annotations' xs={12}>
                <Stack direction='row' spacing={2}>
                    <PolypAnnotationDownload />
                    <DownloadMergedHistologyData />
                </Stack>
            </CollapsiblePaperCard>
            <ClinicalReportStatusList />
            <ACECaseListSummary />
        </Grid>
    );
}

function ExclusionsTab() {
    return (
        <Grid container spacing={2}>
            <Exclusions />
        </Grid>
    );
}

const DEFAULT_TAB = 'Cases'.toLowerCase();
const tabsConfig = [
    { label: 'Cases', component: CasesTab },
    { label: 'Summary', component: SummaryTab },
    { label: 'Explore', component: ExploreTab },
    { label: 'Issues', component: IssuesTab },
    { label: 'Queries', component: QueriesTab },
    { label: 'Exclusions', component: ExclusionsTab },
].map((tab) => {
    return { path: tab.label.toLowerCase(), ...tab };
});

export default function ClinicalReportSummary() {
    const { tab: currentTab } = useParams();
    const [value, setValue] = React.useState(currentTab ?? DEFAULT_TAB);
    const { app } = useAceApp();
    const { data: checkData } = useGgCheckConnectionRead({ pathParams: { app } });
    const navigate = useNavigate();

    return (
        <TabContext value={value}>
            <TabList
                onChange={(e, v) => {
                    setValue(v);
                    navigate(`/clinicalsummary/${v}`);
                }}
                centered
            >
                {tabsConfig.map((tab) => (
                    <Tab key={tab.path} value={tab.path} label={tab.label} />
                ))}
            </TabList>
            {tabsConfig.map((tab) => (
                <TabPanel key={tab.path} value={tab.path}>
                    {checkData && checkData.success ? createElement(tab.component) : <GGAuthErrorBox />}
                </TabPanel>
            ))}
        </TabContext>
    );
}
