const splitThresholdRatio = 0.007;

export function modelToTimeline(data, time, names, colors, minFrames = 32) {
    if (!Array.isArray(data) || !time || data.length === 0) return null;
    const timespan = time.end - time.start;
    const dataLength = data.length;
    const timestamp = (x) => Math.trunc((x * timespan) / dataLength);
    let start = 0;
    let last = data[0];
    const chartData = [];
    for (let i = start + 1; i < dataLength; ++i) {
        if (data[i] !== 0 && !data[i]) continue;
        if (data[i] !== last) {
            if (last !== 0 && i - start > minFrames) {
                chartData.push({
                    val: names?.[last - 1] ?? 'Error',
                    start: timestamp(start),
                    end: timestamp(i),
                    color: colors?.[last - 1] ?? 'gray',
                });
            }
            start = i;
            last = data[i];
        }
    }
    if (last !== 0) {
        chartData.push({
            val: names?.[last - 1] ?? 'Error',
            start: timestamp(start),
            end: timestamp(dataLength),
            color: colors?.[last - 1] ?? 'gray',
        });
    }
    return chartData;
}

export function modelApplyEdits(view, edits, time) {
    const data = view?.data.slice();
    if (!edits || !Array.isArray(data) || !time || data.length === 0) return view;
    const timespan = time.end - time.start;
    const dataLength = data.length;
    const frame = (x) => Math.trunc((x / timespan) * dataLength);
    const modelEdits = edits.filter((e) => e.model === view.name);

    for (const edit of modelEdits) {
        const start = Math.max(frame(edit.start), 0);
        const end = Math.min(frame(edit.end), dataLength);
        for (let i = start; i < end; ++i) {
            data[i] = edit.type === 'add' ? 0 : 1;
        }
    }

    return { ...view, data };
}

export function predictionsToTimeline(data, time) {
    if ((data?.length ?? 0) === 0) {
        return [];
    }
    const chartData = [];
    const timespan = time.end - time.start;
    let start = data[0].timestamp;
    let prev = start;
    for (const pred of data) {
        const dt = pred.timestamp - prev;
        if (dt > timespan * splitThresholdRatio) {
            chartData.push({
                start: start - time.start,
                end: prev - time.start,
                val: 'Detection',
            });
            start = pred.timestamp;
        }
        prev = pred.timestamp;
    }
    chartData.push({
        start: start - time.start,
        end: prev - time.start,
        val: 'Detection',
    });
    const splitThreshold = timespan * splitThresholdRatio;
    return chartData.filter((detection) => detection.end - detection.start > splitThreshold);
}

const get_pred_attr = (pred, attr_key) => {
    return pred?.attributes.find((x) => x.key === attr_key)?.value ?? null;
};

export function statusToTimeline(data, time) {
    if ((data?.length ?? 0) === 0) return;
    const chartData = [];
    let prev = null;
    for (const pred of data) {
        if (
            prev &&
            get_pred_attr(prev, 'detection_status') === 'OFF' &&
            get_pred_attr(pred, 'detection_status') === 'ON'
        ) {
            chartData.push({
                start: prev.timestamp - time.start,
                end: pred.timestamp - time.start,
                val: 'Detection Off',
            });
        } else if (prev === null && get_pred_attr(pred, 'detection_status') === 'ON') {
            chartData.push({
                start: 0,
                end: pred.timestamp - time.start,
                val: 'Detection Off',
            });
        }
        prev = pred;
    }
    if (prev && get_pred_attr(prev, 'detection_status') === 'OFF') {
        chartData.push({
            start: prev.timestamp - time.start,
            end: time.end - time.start,
            val: 'Detection Off',
        });
    }
    return chartData;
}

export function getFixedPredictions(predictions, detectionStatus) {
    if ((predictions?.length ?? 0) === 0) {
        return [];
    }
    if ((detectionStatus?.length ?? 0) === 0) {
        return predictions;
    }
    const fixIntervals = (arr1, arr2, preds) => {
        const newPreds = preds.slice();
        let i = 0,
            j = 0;
        const n = arr1.length,
            m = arr2.length;
        const toSplit = [];
        const toDelete = [];
        while (i < n && j < m) {
            const l = Math.max(arr1[i][0], arr2[j][0]);
            const r = Math.min(arr1[i][1], arr2[j][1]);
            if (l <= r) {
                if (l === arr2[j][0] && r === arr2[j][1]) {
                    toSplit.push({ i, l, r });
                } else if (l === arr1[i][0] && r === arr1[i][1]) {
                    toDelete.push(i);
                } else if (l === arr2[j][0]) {
                    newPreds[i].end = l;
                } else if (r === arr2[j][1]) {
                    newPreds[i].start = r;
                }
            }
            if (arr1[i][1] < arr2[j][1]) i++;
            else j++;
        }
        const newnewPreds = [];
        for (let i = 0; i < newPreds.length; ++i) {
            const split = toSplit.find((x) => x.i === i);
            const del = toDelete.find((x) => x === i);
            if (del) continue;
            if (split) {
                newnewPreds.push({
                    start: newPreds[i].start,
                    end: split.l,
                    val: newPreds[i].val,
                });
                newnewPreds.push({
                    start: split.r,
                    end: newPreds[i].end,
                    val: newPreds[i].val,
                });
            } else newnewPreds.push(newPreds[i]);
        }
        return newnewPreds;
    };
    let curpred = predictions;
    let predarr = predictions.map((x) => [x.start, x.end]);
    const detearr = detectionStatus.map((x) => [x.start, x.end]);

    const max_merging_iter = 100;
    let i = 0;
    while (i < max_merging_iter) {
        const fixed = fixIntervals(predarr, detearr, curpred);
        const fixedarr = fixed.map((x) => [x.start, x.end]);
        if (JSON.stringify(predarr) === JSON.stringify(fixedarr)) break;
        curpred = fixed;
        predarr = fixedarr;
        ++i;
    }
    if (i === max_merging_iter)
        console.log('Err: max iter was reached in timeline merging of prediction and detection_status!');
    return curpred;
}

export function findIntervals(timestamps, splitThresholdMs) {
    const intervals = [];
    let intervalStart = null;

    for (let i = 0; i < timestamps.length; i++) {
        if (i === 0 || timestamps[i] - timestamps[i - 1] > splitThresholdMs) {
            if (intervalStart != null) {
                intervals.push([intervalStart, timestamps[i - 1]]);
            }
            intervalStart = timestamps[i];
        }
    }

    if (intervalStart != null) {
        intervals.push([intervalStart, timestamps[timestamps.length - 1]]);
    }
    return intervals;
}

export function filterSmallIntervals(intervals, minIntervalMs) {
    return intervals.filter(([start, end]) => end - start > minIntervalMs);
}

export function timestampIntervalsToTimelineData(intervals, time, val) {
    return intervals.map(([start, end]) => ({
        start: start - time.start,
        end: end - time.start,
        val,
    }));
}

export function binarySearch(ar, el, compare_fn) {
    let m = 0;
    let n = ar.length - 1;
    while (m <= n) {
        const k = (n + m) >> 1;
        const cmp = compare_fn(el, ar[k]);
        if (cmp > 0) {
            m = k + 1;
        } else if (cmp < 0) {
            n = k - 1;
        } else {
            return k;
        }
    }
    return m - 1;
}

export function findGaps(intervals) {
    if (intervals.length <= 1) return [];

    return intervals.slice(0, -1).map((interval, i) => {
        const nextInterval = intervals[i + 1];
        return nextInterval[0] - interval[1];
    });
}
