import { Box, Button, Fade, Grid, Popper, Stack, TextField, Typography } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { useForm } from 'react-hook-form';
import PersonIcon from '@mui/icons-material/Person';
import PeopleIcon from '@mui/icons-material/People';
import { React, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { useCommentsCreate, useCommentsPartialUpdate, useGroupsList, useUsersList } from '../../aceapi/aceComponents';

// draft.js
import { ContentState, EditorState, Modifier, SelectionState } from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import createMentionPlugin, { defaultSuggestionsFilter } from '@draft-js-plugins/mention';
import '@draft-js-plugins/mention/lib/plugin.css';

const maxBodyCharCount = 4096;

const Entry = ({
    mention,
    isFocused,
    // eslint-disable-next-line
    selectMention,
    // eslint-disable-next-line
    searchValue,
    ...parentProps
}) => {
    const username = mention.name.split(' ')[0];
    return (
        <div
            {...parentProps}
            style={{
                display: 'flex',
                alignItems: 'center',
                padding: '8px',
                background: isFocused ? '#f0f0f0' : 'transparent',
                cursor: 'pointer',
            }}
        >
            @{mention.display}
            {mention.groups ? (
                <PersonIcon style={{ marginRight: '8px', color: '#666' }} />
            ) : (
                <PeopleIcon style={{ marginRight: '8px', color: '#666' }} />
            )}
            <div>
                <div>{username}</div>
                {mention.title && <div style={{ fontSize: '0.8em', color: '#999' }}>{mention.title}</div>}
            </div>
        </div>
    );
};

export default function CommentForm({ root = false, edit = null, parent = null, close }) {
    const { uuid } = useParams();
    const { register, handleSubmit, resetField } = useForm();
    const [open, setOpen] = useState(false);

    const { data: availableUsers } = useUsersList({});
    const { data: availableGroups } = useGroupsList({});
    const mentions = [
        ...availableUsers.map((mention) => ({
            name: `${mention.username} ${mention.first_name} ${mention.last_name}`,
            title: `${mention.first_name} ${mention.last_name}`,
            groups: mention.groups.map((group) => group.name),
        })),
        ...availableGroups.map((mention) => ({
            name: mention.name,
        })),
    ];

    // draft.js
    const editor = useRef(null);
    const [editorState, setEditorState] = useState(() => {
        // Add mention to the user you are responding to
        const parentName = parent?.name ? '@' + parent.name : '';
        return edit || parent
            ? EditorState.createWithContent(ContentState.createFromText(edit?.body ?? parentName))
            : EditorState.createEmpty();
    });
    const [suggestions, setSuggestions] = useState(mentions);

    const queryClient = useQueryClient();

    const { mutateAsync: patchComment } = useCommentsPartialUpdate();
    const { mutateAsync: createComment } = useCommentsCreate();

    const onSubmit = (e) => {
        const comment = {
            body: editorState.getCurrentContent().getPlainText(),
            name: e.name,
        };
        if (edit) {
            patchComment({ pathParams: { id: edit.id }, body: comment })
                .then(async () => {
                    await queryClient.invalidateQueries({ queryKey: ['procedures', uuid, 'comments'] });
                    close && close();
                })
                .catch((err) => {
                    console.log(err);
                    alert('An unexpected error occurred');
                });
        } else {
            createComment({ body: { ...comment, parent: parent?.id, procedure_id: uuid } })
                .then(async () => {
                    resetField('body');
                    setEditorState(EditorState.push(editorState, ContentState.createFromText('')));
                    await queryClient.invalidateQueries({ queryKey: ['procedures', uuid, 'comments'] });
                    if (!root && close) close();
                })
                .catch((err) => {
                    console.log(err);
                    alert('An unexpected error occurred');
                });
        }
    };
    const handleEditorChange = (newEditorState) => {
        const afterValue = newEditorState.getCurrentContent().getPlainText();
        if (afterValue.length > maxBodyCharCount) {
            setEditorState(editorState);
        } else {
            setEditorState(newEditorState);
        }
    };

    const handleReturn = () => {
        const value = editorState.getCurrentContent().getPlainText();
        if (value.length + 1 > maxBodyCharCount) {
            return 'handled';
        }
        return 'not-handled';
    };
    const handleBeforeInput = () => {
        const currentContent = editorState.getCurrentContent();
        const currentContentLength = currentContent.getPlainText('').length;

        if (currentContentLength > maxBodyCharCount - 1) {
            return 'handled';
        }
    };
    const handlePastedText = (pasted) => {
        const contentState = editorState.getCurrentContent();

        const anchorOffset = editorState.getSelection().getAnchorOffset();
        const beforeCursor = contentState.getPlainText().substring(0, anchorOffset);
        const afterCursor = contentState.getPlainText().substring(anchorOffset);
        const newText = `${beforeCursor}${pasted}${afterCursor}`;
        const overflowChars = newText.length - maxBodyCharCount;

        const firstBlockKey = contentState.getFirstBlock().getKey();
        const lastBlockKey = contentState.getLastBlock().getKey();
        const lastBlock = contentState.getBlockForKey(lastBlockKey);
        const lastBlockLength = lastBlock.getLength();

        const newselectionState = SelectionState.createEmpty(firstBlockKey).merge({
            anchorKey: firstBlockKey,
            anchorOffset: 0,
            focusKey: lastBlockKey,
            focusOffset: lastBlockLength,
            hasFocus: true,
        });

        if (overflowChars > 0) {
            if (newText.length - overflowChars > 0) {
                const trunText = newText.substring(0, maxBodyCharCount);
                const newContent = Modifier.replaceText(contentState, newselectionState, trunText);
                const newEditor = EditorState.push(editorState, newContent);
                setEditorState(newEditor);
            }
            return 'handled';
        } else {
            return 'not-handled';
        }
    };

    const MentionComponent = (mentionProps) => {
        const [openP, setOpenP] = useState(false);
        const [anchorEl, setAnchorEl] = useState(null);
        const username = mentionProps.mention.name.split(' ')[0];

        const handleHover = (event) => {
            setAnchorEl(event.currentTarget);
            setOpenP(true);
        };
        const handleMouseLeave = () => {
            setOpenP(false);
        };
        return (
            <>
                <span
                    className={mentionProps.className}
                    onMouseOver={handleHover}
                    onMouseLeave={handleMouseLeave}
                    style={{ cursor: 'pointer', color: '#3f51b5' }}
                >
                    @{username}
                </span>
                <Popper open={openP} anchorEl={anchorEl} placement='top' transition>
                    {({ TransitionProps }) => (
                        <Fade {...TransitionProps}>
                            <Box
                                sx={{
                                    border: 0.1,
                                    p: 1,
                                    bgcolor: 'background.paper',
                                    boxShadow: 3,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'start',
                                    fontSize: '14px',
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        mb: 0.5,
                                    }}
                                >
                                    {mentionProps.mention.groups ? (
                                        <PersonIcon style={{ marginRight: '8px', color: '#666' }} />
                                    ) : (
                                        <PeopleIcon style={{ marginRight: '8px', color: '#666' }} />
                                    )}
                                    <Typography sx={{ fontWeight: 'bold', fontSize: '16px', color: 'text.primary' }}>
                                        {`${username}`}
                                    </Typography>
                                </Box>
                                {mentionProps.mention.title ? (
                                    <Typography
                                        sx={{ fontWeight: 'italic', fontSize: '13px', color: 'text.secondary' }}
                                    >
                                        {`Full name: ${mentionProps.mention.title}`}
                                    </Typography>
                                ) : null}
                                {mentionProps.mention.groups ? (
                                    <Typography
                                        sx={{ fontWeight: 'italic', fontSize: '13px', color: 'text.secondary' }}
                                    >
                                        {`Groups: ${mentionProps.mention.groups}`}
                                    </Typography>
                                ) : null}
                            </Box>
                        </Fade>
                    )}
                </Popper>
            </>
        );
    };
    const { MentionSuggestions, plugins } = useMemo(() => {
        const mentionPlugin = createMentionPlugin({
            mentionComponent: MentionComponent,
            mentionTrigger: ['@'],
            mentionPrefix: (trigger) => trigger,
        });
        const { MentionSuggestions } = mentionPlugin;
        const plugins = [mentionPlugin];
        return { plugins, MentionSuggestions };
    }, []);

    const onOpenChange = (open) => setOpen(open);

    const onSearchChange = ({ value }) => {
        setSuggestions(defaultSuggestionsFilter(value, mentions));
    };

    const onAddMention = (mention) => {
        const username = mention.name.split(' ')[0];
        const currentContent = editorState.getCurrentContent();
        const currentText = currentContent.getPlainText();
        const mentionLength = username.length;

        // Check if adding the mention exceeds the limit
        if (currentText.length + mentionLength > maxBodyCharCount) {
            alert(`Exceeding ${maxBodyCharCount} character limit.`);
        }
        mention.name = username;
    };
    // Focus on editor window
    const focusEditor = () => {
        editor.current.focus();
    };
    return (
        <Box m={2} component='form' onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2} alignItems='center'>
                <Grid item xs={2}>
                    <Typography variant='h5'>
                        {edit ? 'Edit comment' : root ? 'Add new comment' : 'Reply to comment'}
                    </Typography>
                </Grid>
                <Grid item xs={10}>
                    <TextField
                        label='Displayed Name or Subject (optional)'
                        fullWidth
                        inputProps={{ maxLength: 128 }}
                        defaultValue={edit && edit.name}
                        {...register('name')}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ position: 'relative' }}>
                        <div
                            style={{ border: '1px solid #ccc', padding: '10px', minHeight: '6em' }}
                            onClick={() => focusEditor()}
                        >
                            <Editor
                                editorKey={'editor'}
                                editorState={editorState}
                                onChange={handleEditorChange}
                                handleBeforeInput={handleBeforeInput}
                                handlePastedText={handlePastedText}
                                handleReturn={handleReturn}
                                plugins={plugins}
                                ref={editor}
                            />
                            <MentionSuggestions
                                open={open}
                                onOpenChange={onOpenChange}
                                suggestions={suggestions}
                                onSearchChange={onSearchChange}
                                onAddMention={onAddMention}
                                entryComponent={Entry}
                            />
                        </div>
                        <div style={{ fontSize: '12px', color: '#666', marginTop: '4px', width: '100%' }}>
                            Remaining characters:{' '}
                            {maxBodyCharCount - editorState.getCurrentContent().getPlainText('').length || 0}
                        </div>
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    <Stack direction='row' spacing={2}>
                        <Button
                            type='submit'
                            variant='contained'
                            disabled={editorState.getCurrentContent().getPlainText('').length === 0}
                            endIcon={<SendIcon />}
                        >
                            Submit
                        </Button>
                        {!root && (
                            <Button variant='contained' onClick={close}>
                                Cancel
                            </Button>
                        )}
                    </Stack>
                </Grid>
            </Grid>
        </Box>
    );
}
