import { Autocomplete, Button, IconButton, MenuItem, Modal, Stack, TextField } from '@mui/material';
import ConfirmButton from '../dialogs/ConfirmButton';
import ClearIcon from '@mui/icons-material/Clear';
import FrameBox from '../Charts/timeline/FrameBox';
import FrameZoom from '../Charts/timeline/FrameZoom';
import { useEffect, useMemo, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import useProcTime from '../../aceapi/hooks/useProcTime';
import useAuthorized from '../../aceapi/hooks/useAuthorized';
import useShow from '../../aceapi/hooks/useShow';
import { useQueryClient } from '@tanstack/react-query';
import {
    useEditsTimelineCreate,
    useEditsTimelineDelete,
    useEditsTimelinePartialUpdate,
    useProceduresTimelineEdits,
} from '../../aceapi/aceComponents';
import { useParams } from 'react-router-dom';
import UpdateIcon from '@mui/icons-material/Update';
import useExtraData from '../report/useExtraData';
import { useAceApp } from '../Menu/ReportAppSelector';
import format from 'format-duration';
import useGGPolypData from './guru/useGGPolypData';
import DownloadIcon from '@mui/icons-material/Download';
import clsx from 'clsx';

const VISUAL_DIAG_CHOICES = [
    'uncertain',
    'adenoma',
    'non-adenoma',
    'no polyp',
    'other neoplastic',
    'other non-neoplastic',
    'no comment',
];
const IMAGE_QUAL_CHOICES = ['high', 'medium', 'low'];
const MODE_CHOICES = ['nbi', 'wle'];
const ENDO_TOOL_CHOICES = ['forceps', 'clip', 'snare', 'rothnet', 'not resected'];
const IN_RECTOSIGMOID_CHOICES = ['yes', 'no', 'uncertain'];

const DIAGNOSIS_MAPPING = {
    adenoma: 'adenoma',
    hyperplastic: 'non-adenoma',
    'sessile serrated lesion (ssl)': 'non-adenoma',
    cancer: 'adenoma',
    'other neoplastic': 'other neoplastic',
    'other non-neoplastic': 'other non-neoplastic',
    'not done': 'no comment',
};

// source_field: dest_field, transform, validator
const GG_MAPPING = {
    initial_endoscopist_optical_diagnosis_of_polyp: [
        'visual_diagnosis',
        (x) => DIAGNOSIS_MAPPING[x?.toLowerCase()],
        (x) => !x || VISUAL_DIAG_CHOICES.includes(x),
    ],
    "second_endoscopist's_optical_diagnosis_(applicable_only_for_patients_in_ai_arm)": [
        'expert_diagnosis',
        (x) => DIAGNOSIS_MAPPING[x?.toLowerCase()],
        (x) => !x || VISUAL_DIAG_CHOICES.includes(x),
    ],
    please_choose_the_instrument_chosen_to_remove_this_polyp: [
        'endo_tool',
        (x) => {
            const lower = x.toLowerCase();
            if (ENDO_TOOL_CHOICES.includes(lower)) {
                return lower;
            }
            for (const tool of ENDO_TOOL_CHOICES) {
                if (lower.includes(tool)) {
                    return tool;
                }
            }
            return x;
        },
        (x) => ENDO_TOOL_CHOICES.includes(x),
    ],
};

export default function CadxAnnotation(props) {
    const { frames, seeker } = props;
    const { app } = useAceApp();
    const procTime = useProcTime();
    const { uuid } = useParams();
    const authorized = useAuthorized();
    const show = useShow();
    const queryClient = useQueryClient();

    const [clickedFrame, setClickedFrame] = useState(null);

    const { mutateAsync: createEdit } = useEditsTimelineCreate();
    const { mutateAsync: deleteEdit } = useEditsTimelineDelete();
    const { mutateAsync: partialUpdateEdit } = useEditsTimelinePartialUpdate();

    const { extraModels, edits } = useExtraData({
        show: show.extra_models,
        authorized,
    });

    const newPolypAnnotation = useMemo(() => extraModels.find((x) => x.key === 'newpolyp_annotation'), [extraModels]);

    const { data: timelineEdits } = useProceduresTimelineEdits(
        {
            pathParams: { procedureId: uuid },
            queryParams: { app },
        },
        { enabled: authorized && show.timeline, suspense: false },
    );

    useEffect(() => {
        edits.setState(timelineEdits ?? []);
    }, [timelineEdits, edits]);

    const filteredEdits = useMemo(
        () => edits.state?.filter((x) => x.model === newPolypAnnotation?.id),
        [edits.state, newPolypAnnotation?.id],
    );

    const [rowStatus, setRowStatus] = useState({});

    const ggPolyps = useGGPolypData();

    const doesRowHaveAnAnnotation = (x) => {
        if (!x.row.item) {
            return true;
        }
        return filteredEdits.some(
            (edit) => edit.start === x.row.time - procTime.start && edit.model === newPolypAnnotation?.id,
        );
    };
    const getNewPolypAnnotationByTime = (time) => {
        return filteredEdits.find((edit) => edit.start === time - procTime.start);
    };

    const createSingleFrameAnnotation = (x) => {
        if (doesRowHaveAnAnnotation(x) || !newPolypAnnotation || rowStatus[x.row.time] === 'loading') {
            return;
        }
        setRowStatus((prev) => ({ ...prev, [x.row.time]: 'loading' }));
        createEdit({
            body: {
                procedure_id: uuid,
                type: 'single',
                model: newPolypAnnotation?.id,
                start: x.row.time - procTime.start,
                end: null,
                comment: { diagnosis: x.row.item.diagnosis },
            },
        })
            .then(async () => {
                await queryClient.invalidateQueries({ queryKey: ['procedures', uuid, 'timeline_edits'] });
                setRowStatus((prev) => ({ ...prev, [x.row.time]: 'done' }));
            })
            .catch(() => {
                setRowStatus((prev) => ({ ...prev, [x.row.time]: 'error' }));
            });
    };

    const removeEdit = (x) => {
        const { id } = getNewPolypAnnotationByTime(x.row.time);
        deleteEdit({ pathParams: { id } }).then(async () => {
            await queryClient.invalidateQueries({ queryKey: ['procedures', uuid, 'timeline_edits'] });
        });
    };

    const getValue = (x) => {
        // Handle null case for existing annotations
        const edit = getNewPolypAnnotationByTime(x.row.time);
        if (!edit.comment) {
            return null;
        }

        return edit.comment[x.field]?.valueOf();
    };

    const handleChange = (value, x) => {
        // Handle null case for existing annotations
        const edit = getNewPolypAnnotationByTime(x.row.time);

        let updatedComment = {};
        if (edit.comment) {
            updatedComment = edit.comment;
        }

        if ([null, undefined, ''].includes(value)) {
            delete updatedComment[x.field];
        } else {
            updatedComment[x.field] = value;
        }

        partialUpdateEdit({
            pathParams: { id: edit.id },
            body: { comment: updatedComment },
        }).then(async () => {
            await queryClient.invalidateQueries({ queryKey: ['procedures', uuid, 'timeline_edits'] });
        });
    };

    const pullGGPolypData = (x) => {
        if (!doesRowHaveAnAnnotation(x)) {
            return;
        }

        const edit = getNewPolypAnnotationByTime(x.row.time);

        const polypId = edit.comment?.polyp_id;
        if ([null, undefined, ''].includes(polypId)) {
            return;
        }

        const polypData = ggPolyps.data?.[polypId];
        if (!polypData) {
            alert(`Polyp ID ${polypId} not found in GG Polyp Data`);
            return;
        }

        const updatedComment = { ...edit.comment };
        Object.entries(GG_MAPPING).forEach(([srcField, [destField, transform, validator]]) => {
            const value = polypData[srcField];
            if ([null, undefined, ''].includes(value)) {
                return;
            }
            const transformedValue = transform(value);
            if (validator(transformedValue)) {
                updatedComment[destField] = transformedValue;
            } else if (value) {
                alert(`Invalid value for ${srcField}: ${value}`);
            }
        });

        partialUpdateEdit({
            pathParams: { id: edit.id },
            body: { comment: updatedComment },
        }).then(async () => {
            await queryClient.invalidateQueries({ queryKey: ['procedures', uuid, 'timeline_edits'] });
        });
    };

    // Helper functions for the datagrid cells
    const renderPolypIdField = (x, handleChange) => {
        return (
            doesRowHaveAnAnnotation(x) && (
                <Stack spacing={1} direction='row'>
                    <Autocomplete
                        size='small'
                        sx={{ width: 150 }}
                        options={ggPolyps.ids}
                        renderInput={(params) => <TextField {...params} />}
                        onChange={(e, value) => handleChange(value, x)}
                        value={getValue(x) ?? ''}
                        freeSolo
                    />
                    <ConfirmButton
                        color='primary'
                        disabled={!ggPolyps.ids.includes(getValue(x))}
                        ButtonType={IconButton}
                        action='replace the annotation with the GG Polyp Data for this polyp'
                        onConfirm={() => pullGGPolypData(x)}
                    >
                        <DownloadIcon />
                    </ConfirmButton>
                </Stack>
            )
        );
    };

    const renderSelectField = (x, handleChange, options) => {
        return !doesRowHaveAnAnnotation(x) ? (
            ''
        ) : (
            <TextField
                select
                size='small'
                sx={{ width: '100%' }}
                onChange={(e) => handleChange(e.target.value, x)}
                value={getValue(x) ?? ''}
            >
                <MenuItem value=''>
                    <em>None</em>
                </MenuItem>
                {options.map((option) => (
                    <MenuItem key={option} value={option}>
                        {option}
                    </MenuItem>
                ))}
            </TextField>
        );
    };

    const frame_columns = [
        {
            field: 'image',
            headerName: '',
            width: 100,
            align: 'center',
            renderCell: (x) => <FrameBox borderThickness='0' frame={x.row.item} setClicked={setClickedFrame} />,
        },
        {
            field: 'jump',
            headerName: '',
            width: 50,
            renderCell: (x) => (
                <IconButton onClick={() => seeker.seekTo(x.row.time)}>
                    <UpdateIcon />
                </IconButton>
            ),
        },
        {
            field: 'pedal_press',
            headerName: 'Pedal Press',
            align: 'center',
            width: 100,
            renderCell: (x) => x.id,
        },
        {
            field: 'add_annotation',
            headerName: 'Add Label',
            width: 100,
            renderCell: (x) =>
                !doesRowHaveAnAnnotation(x) && (
                    <Button
                        onClick={() => createSingleFrameAnnotation(x)}
                        disabled={rowStatus[x.row.time] === 'loading'}
                    >
                        Add
                    </Button>
                ),
        },
        {
            field: 'polyp_id',
            headerName: 'Polyp ID',
            width: 250,
            renderCell: (x) => renderPolypIdField(x, handleChange),
        },
        {
            field: 'caddie_prediction',
            headerName: 'CADx',
            width: 100,
            renderCell: (x) => (!x.row.item ? '' : x.row.item.diagnosis),
        },
        {
            field: 'visual_diagnosis',
            headerName: 'Initial OD',
            width: 140,
            renderCell: (x) => renderSelectField(x, handleChange, VISUAL_DIAG_CHOICES),
        },
        {
            field: 'expert_diagnosis',
            headerName: 'Second OD',
            width: 140,
            renderCell: (x) => renderSelectField(x, handleChange, VISUAL_DIAG_CHOICES),
        },
        {
            field: 'image_quality',
            headerName: 'Image Quality',
            width: 100,
            renderCell: (x) => renderSelectField(x, handleChange, IMAGE_QUAL_CHOICES),
        },
        {
            field: 'mode',
            headerName: 'Mode',
            width: 100,
            renderCell: (x) => renderSelectField(x, handleChange, MODE_CHOICES),
        },
        {
            field: 'endo_tool',
            headerName: 'Endo Tool',
            width: 100,
            renderCell: (x) => renderSelectField(x, handleChange, ENDO_TOOL_CHOICES),
        },
        {
            field: 'in_rectosigmoid',
            headerName: 'In Rectosigmoid?',
            width: 100,
            renderCell: (x) => renderSelectField(x, handleChange, IN_RECTOSIGMOID_CHOICES),
        },
        {
            field: 'time',
            headerName: 'Time',
            width: 100,
            renderCell: (x) => format(x.row.time - procTime.start),
        },
        {
            field: 'annotator',
            headerName: 'Annotator',
            width: 100,
            valueGetter: (x) => getNewPolypAnnotationByTime(x.row.time)?.user?.username ?? 'Not Annotated',
        },
        {
            field: 'delete',
            headerName: 'Delete',
            width: 60,
            renderCell: (x) => (
                <ConfirmButton ButtonType={IconButton} onConfirm={() => removeEdit(x)} action='delete this edit'>
                    <ClearIcon />
                </ConfirmButton>
            ),
        },
    ].map((x) => ({
        ...x,
        // highlight the value that differ from what the autocomplete would suggest
        cellClassName: (params) => {
            const edit = getNewPolypAnnotationByTime(params.row.time);
            if (!edit || !edit.comment) {
                return '';
            }
            const polypId = edit.comment.polyp_id;
            if (!polypId) {
                return '';
            }

            const ggPolypData = ggPolyps.data?.[polypId];
            if (!ggPolypData) {
                return '';
            }

            const srcField = Object.keys(GG_MAPPING).find((key) => GG_MAPPING[key][0] === x.field);
            if (!srcField) {
                return '';
            }

            const value = edit.comment[x.field];
            const transformedValue = GG_MAPPING[srcField][1](ggPolypData[srcField]);

            return clsx('gg-cadx-diff', { highlight: transformedValue !== value });
        },
    }));

    const frame_rows = useMemo(() => {
        const filteredFrames = (frames ?? []).filter((x) =>
            ['adenoma', 'non-adenoma', 'uncertain'].includes(x.diagnosis?.toLowerCase()),
        );

        return [
            ...filteredFrames.map((item, index) => ({
                id: index + 1,
                item: item,
                time: item.timestamp,
            })),
            ...filteredEdits
                .filter((item) => !filteredFrames.find((x) => x.timestamp === item.start + procTime.start))
                .sort((a, b) => a.start - b.start)
                .map((item, index) => ({
                    id: 'X' + (index + 1),
                    item: item,
                    time: item.start + procTime.start,
                })),
        ];
    }, [frames, filteredEdits, procTime.start]);

    const pullAllGGPolypData = () => {
        frame_rows.forEach((x) => {
            pullGGPolypData({ row: x });
        });
    };

    return (
        frames && (
            <>
                <DataGrid
                    columns={frame_columns}
                    rows={frame_rows}
                    getRowId={(row) => row.id}
                    autoHeight
                    rowHeight={80}
                    sx={{
                        '& .gg-cadx-diff.highlight': { backgroundColor: '#ffcccc' },
                    }}
                />
                {ggPolyps.ids && (
                    <ConfirmButton
                        color='primary'
                        variant='contained'
                        disabled={frame_rows.length === 0}
                        action='replace all annotations with the GG Polyp Data for the polyps'
                        onConfirm={pullAllGGPolypData}
                    >
                        Pull All GG Polyp Data
                    </ConfirmButton>
                )}
                <Modal open={clickedFrame !== null} onClose={() => setClickedFrame(null)}>
                    <div>{Array.isArray(frames) && <FrameZoom frame={clickedFrame} frames={frames} />}</div>
                </Modal>
            </>
        )
    );
}
