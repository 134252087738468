import { AsyncBoundary } from '../../aceapi/utils';
import { Alert, Box, Button, Collapse, LinearProgress, Skeleton, Snackbar, Stack, Typography } from '@mui/material';
import { useContext, useEffect, useMemo, useState } from 'react';
import FlexBox from '../report/FlexBox';
import { LoadingButton } from '@mui/lab';
import SyncIcon from '@mui/icons-material/Sync';
import {
    useExtraCleanup,
    useExtraComputeMissing,
    useExtraList,
    useExtraMissing,
    useExtraTrackList,
    useSummaryProceduresMaxDuration,
} from '../../aceapi/aceComponents';
import { useAceApp } from '../Menu/ReportAppSelector';
import { useQueryClient } from '@tanstack/react-query';
import { ComputeAnnotationContext } from '../../config/contexts';

function ComputeMissingAnnotations({ job, setCompute, setSuccess, setIsComputingAnnotations, app }) {
    const [refresh, setRefresh] = useState(false);
    const { data: track } = useExtraTrackList(
        { queryParams: { app, job } },
        { enabled: !!job, refetchInterval: refresh ? 200 : false },
    );

    const { state, current, total } = track || {};

    const polling = useMemo(() => state === 'STARTED' || state === 'PENDING' || total > 0, [state, total]);
    useEffect(() => {
        if (refresh !== polling) {
            setRefresh(polling);
        }
    }, [polling, refresh]);

    useEffect(() => {
        if (state === 'SUCCESS') {
            setSuccess(true);
            setCompute(false);
            setIsComputingAnnotations(false);
        } else if (state !== 'PENDING') {
            // on error
            setIsComputingAnnotations(false);
        }
    }, [setCompute, setIsComputingAnnotations, setSuccess, state]);

    if (total > 0)
        return (
            <Stack spacing={2}>
                <Typography variant='h6'>Computing missing annotations...</Typography>
                <FlexBox alignItems='center'>
                    <Box width='100%' mr={1}>
                        <LinearProgress color='success' variant='determinate' value={(current * 100) / total} />
                    </Box>
                    <Box minWidth={120}>
                        <Typography variant='body2'>{`${current}/${total}`}</Typography>
                    </Box>
                </FlexBox>
            </Stack>
        );

    if (state === 'PENDING')
        return (
            <Stack spacing={2}>
                <Typography variant='h6'>Task pending...</Typography>
                <LinearProgress color='warning' />
            </Stack>
        );

    if (state === 'SUCCESS') {
        return <Typography variant='h6'>Task completed!</Typography>;
    }

    return state ? (
        <Typography variant='h6' color='error'>
            Task failed! Reason: {state}
        </Typography>
    ) : (
        <Typography variant='h6' color='error'>
            Task failed for unknown reason.
        </Typography>
    );
}

function AsyncSummaryUpdate() {
    const { app } = useAceApp();
    const queryClient = useQueryClient();
    const { data: missing_annotations } = useExtraMissing({ queryParams: { app } });
    const missing = missing_annotations?.missing;

    const [visible, setVisible] = useState(true);
    const [compute, setCompute] = useState(false);
    const [success, setSuccess] = useState(false);
    const [showFetched, setShowFetched] = useState(false);

    useEffect(() => {
        setSuccess(false);
    }, [app]);

    const { data: extraJob, mutate: computeMissing } = useExtraComputeMissing({
        onSuccess: (data) => {
            console.log('Started computing missing annotations with job id', data?.job ?? 'unknown');
            setCompute(true);
        },
        onError: (error) => {
            console.error('Failed to request computing missing annotations:', error ?? 'unknown');
            setCompute(false);
        },
    });
    const { setIsComputingAnnotations } = useContext(ComputeAnnotationContext);
    const handleComputeMissing = () => {
        computeMissing({ queryParams: { app } });
        setIsComputingAnnotations(true);
    };
    const { isFetching: isFetchingExtra } = useExtraList({ queryParams: { app } }, { suspense: false });
    const { isFetching: isFetchingProcs } = useSummaryProceduresMaxDuration(
        { queryParams: { app } },
        { suspense: false },
    );
    const fetching = isFetchingExtra || isFetchingProcs;
    const fetched = !fetching;

    const { mutateAsync: runCleanup, isPending: cleaning } = useExtraCleanup();

    const refetch_annotations = async () => {
        await queryClient.invalidateQueries({ queryKey: ['extra'] });
        await queryClient.invalidateQueries({ queryKey: ['summary', 'procedures', 'max_duration'] });
        setShowFetched(true);
        setSuccess(false);
        setVisible(false);
    };
    if (missing < 0) {
        return (
            <Stack spacing={2} alignItems='center'>
                <Typography variant='h6'>
                    Unexpected annotations were found, please cleanup before proceeding.
                </Typography>
                <LoadingButton
                    variant='contained'
                    color='warning'
                    loading={cleaning}
                    onClick={() => {
                        runCleanup({ queryParams: { app } }).then(async (res) => {
                            console.log('Annotations deleted:', res?.deleted ?? 'unknown');
                            await queryClient.invalidateQueries({ queryKey: ['extra', 'missing'] });
                        });
                    }}
                >
                    Cleanup annotations
                </LoadingButton>
            </Stack>
        );
    }

    if (missing === 0) return null;

    if (!missing) {
        return (
            <Typography align='center' color='error'>
                Could not fetch missing annotation status, <b>this summary might be outdated</b>.
            </Typography>
        );
    }

    return (
        <>
            <Collapse in={visible}>
                <Stack p={2} spacing={2}>
                    <Typography>
                        <b>The summary is outdated! </b>
                        There are at least {missing} procedure that have not yet been annotated and are therefore not
                        currently included in the summary. It might take a while to compute.
                    </Typography>
                    <Collapse in={!compute && !success} unmountOnExit>
                        <Stack direction='row' spacing={2}>
                            <Button
                                variant='contained'
                                onClick={handleComputeMissing}
                                disabled={fetching || compute || success}
                            >
                                Compute missing annotations
                            </Button>
                            <Button variant='contained' color='error' onClick={() => setVisible(false)}>
                                Ignore for now
                            </Button>
                        </Stack>
                    </Collapse>
                    <Collapse in={compute} unmountOnExit>
                        <AsyncBoundary
                            fallback={
                                <Stack spacing={2}>
                                    <Typography variant='h6'>Submitting request...</Typography>
                                    <LinearProgress color='secondary' />
                                </Stack>
                            }
                        >
                            <ComputeMissingAnnotations
                                job={extraJob?.job}
                                setCompute={setCompute}
                                setSuccess={setSuccess}
                                setIsComputingAnnotations={setIsComputingAnnotations}
                                app={app}
                            />
                        </AsyncBoundary>
                    </Collapse>
                    <Collapse in={success} unmountOnExit>
                        <Stack direction='row' spacing={2} alignItems='center'>
                            <Typography variant='h6' color='success.main'>
                                The missing annotations were successfully computed!
                            </Typography>
                            <LoadingButton
                                variant='contained'
                                color='success'
                                onClick={refetch_annotations}
                                loading={fetching}
                                loadingPosition='start'
                                startIcon={<SyncIcon />}
                            >
                                Fetch new annotations
                            </LoadingButton>
                        </Stack>
                    </Collapse>
                </Stack>
            </Collapse>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                open={fetched && showFetched}
                autoHideDuration={10000}
                onClose={() => setShowFetched(false)}
            >
                <Alert severity='success' onClose={() => setShowFetched(false)} variant='filled'>
                    The new annotations were fetched successfully! Some things might not update properly, please reload
                    the page if you want to make sure everything is up to date.
                </Alert>
            </Snackbar>
        </>
    );
}

export default function SummaryUpdate(props) {
    return (
        <AsyncBoundary fallback={<Skeleton variant='rounded' height={110} animation='wave' />}>
            <AsyncSummaryUpdate {...props} />
        </AsyncBoundary>
    );
}
